<template>
  <div>
    <div class="login_sign">
      <div class="login-form-title">
        <img src="@/assets/images/icons/logo.png" alt="logo" @click="goHomeFun">
        <h2>欢迎打开<span>厨窗+</span></h2>
      </div>
      <a-form
        :model="formState"
        :rules="rules"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="user">
          <a-input v-model:value="formState.user" autocomplete="off" placeholder="邮箱/手机号" class="ainput" />
        </a-form-item>
        <a-form-item name="password">
          <a-input
            v-model:value="formState.password"
            type="password"
            placeholder="密码"
            autocomplete="off"
            class="ainput"
          />
        </a-form-item>
        <div class="other_info">
          <span class="forget_password" @click="otherSituationFun('forget')">忘记密码？</span>
          <span class="register" @click="otherSituationFun('register')">还没有账号？点击注册</span>
        </div>

        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="formState.user === '' || formState.password === ''"
            class="abutton"
            :loading="loading"
          >
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/login'
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Sign',
  components: {
  },
  emits: ['tother'],
  data() {
    return {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      rules: {
        user: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码6-18位', trigger: 'blur' }
        ]
      },
      formState: {
        user: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo'
    }),
    async handleFinish(values) { // 登录
      this.loading = true
      const data = {
        loginacct: this.formState.user,
        password: this.formState.password
      }
      login(data).then(res => {
        if (res.code === 0) {
          Cookies.set('MALL_TOKEN', res.token)
          this.getUserInfo()
          const MallToPath = sessionStorage.getItem('MallToPath')
          if (MallToPath) {
            if (sessionStorage.getItem('MallToPathQuery') !== '{}') {
              this.$router.replace({ path: MallToPath, query: JSON.parse(sessionStorage.getItem('MallToPathQuery')) })
              sessionStorage.removeItem('MallToPathQuery')
            } else {
              this.$router.replace({ path: MallToPath })
            }
            sessionStorage.removeItem('MallToPath')
          } else this.$router.replace({ path: '/' })
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(_ => {
        this.loading = false
      })
    },
    goHomeFun() {
      this.$router.replace({ path: '/' })
    },
    handleFinishFailed(errors) {
      console.log(errors)
    },
    otherSituationFun(val) { // 跳转注册
      this.$emit('tother', val)
    }
  }
}

</script>
<style lang='less' scoped>
.login_sign {

  .login-form-title {

    & > img {
      width: 64px;
      height: 64px;
      margin-bottom: 40px;
      cursor: pointer;
    }
    & > h2 {
      color: @main-color;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 72px;
    }
    & > h2 > span {
      color: @color;
    }
  }

  .ainput {
    width: 480px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #E5E5E5;
    outline: none !important;
    background: none !important;
    box-shadow: none;
    font-size: 18px;
    &:focus {
      border:none !important;
      border-bottom: 1px solid #E5E5E5 !important;
    }
  }

  .abutton {
    width: 480px;
    height: 56px;
    background: @bg-color;
    border: 1px solid #E5E5E5;
    border-radius: 33px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .other_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .forget_password {
      display: inline-block;
      color: @color;
      font-weight: 400;
      margin-bottom: 64px;
      cursor: pointer;
    }
    .register {
      display: inline-block;
      color: @color;
      font-weight: 400;
      margin-bottom: 64px;
      cursor: pointer;
    }
  }

}

</style>
