<template>
  <div>
    <div class="login_resgiter">
      <div class="login-form-title">
        <img src="@/assets/images/icons/logo.png" alt="logo" @click="goHomeFun">
        <h2>欢迎打开<span>厨窗+</span></h2>
      </div>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="userName">
          <a-input
            v-model:value="formState.userName"
            autocomplete="off"
            placeholder="用户名"
            class="ainput"
          />
        </a-form-item>
        <a-form-item name="phone">
          <a-input
            v-model:value="formState.phone"
            autocomplete="off"
            placeholder="手机号"
            class="ainput"
          />
        </a-form-item>
        <a-form-item name="password">
          <a-input
            v-model:value="formState.password"
            autocomplete="off"
            placeholder="请输入密码"
            class="ainput"
            type="password"
          />
        </a-form-item>
        <a-form-item name="secPassword">
          <a-input
            v-model:value="formState.secPassword"
            autocomplete="off"
            placeholder="请再次输入密码"
            class="ainput"
            type="password"
          />
        </a-form-item>
        <a-form-item name="yzm">
          <a-input
            v-model:value="formState.yzm"
            autocomplete="off"
            placeholder="验证码"
            class="ainput ainput_yzm db-mr10"
          />
          <a-button class="yzm" :disabled="isGetYzm" @click="getYZMFun">
            <a-statistic-countdown
              v-if="isGetYzm"
              :value="deadline"
              format="mm:ss"
              value-style="font-size: 18px;"
              style=""
              @finish="onFinish"
            />
            <span v-if="!isGetYzm">获取验证码</span>
          </a-button>
        </a-form-item>
        <a-radio
          v-model:checked="checked"
          class="radio_style"
        >我已经接受并同意厨窗+隐私条款</a-radio>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            class="abutton"
            :loading="loading"
            @click="registerFun"
          >
            注册
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
// import { reactive, ref } from 'vue'
import { sendcode, regist } from '@/api/login'

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      deadline: '',
      reg: /^1\d{10}$/,
      rules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        yzm: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码6-18位', trigger: 'blur' }],
        secPassword: [
          { required: true, trigger: 'blur', validator: this.validateToNextPassword }
        ]
      },
      formState: {
        userName: '',
        phone: '',
        yzm: '',
        password: '',
        secPassword: ''
      },
      checked: false,
      isGetYzm: false,
      loading: false
    }
  },
  methods: {
    validateToNextPassword(rule, value) { // 验证密码
      if (value === '') {
        return Promise.reject('请输入确认密码')
      } else if (value !== this.formState.password) {
        return Promise.reject('两次密码不一致')
      } else {
        return Promise.resolve()
      }
    },
    onFinish() { // 验证码结束回调
      this.isGetYzm = false
    },
    getYZMFun() { // 获取验证码
      if (!this.formState.phone) {
        this.$message.error('手机号不能为空')
        return
      }
      if (!this.reg.test(this.formState.phone)) {
        this.$message.error('手机号不正确')
        return
      }
      this.isGetYzm = true
      this.deadline = Date.now() + 1000 * 60 * 5
      const data = {
        phone: this.formState.phone
      }
      sendcode(data).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message.success('获取验证码成功')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleFinish(values) {
      console.log(values)
    },
    handleFinishFailed(errors) {
      console.log(errors)
    },
    registerFun() { // 注册
      if (!this.checked) {
        this.$message.error('请阅读隐私条款并勾选')
        return
      }
      if (!this.reg.test(this.formState.phone)) {
        this.$message.error('手机号不正确')
        return
      }

      this.$refs.formRef.validate().then(res => {
        this.loading = true
        const data = {
          userName: this.formState.userName,
          password: this.formState.password,
          phone: this.formState.phone,
          code: this.formState.yzm
        }
        this.LoginRegistFun(data)
      })
    },
    LoginRegistFun(data) {
      regist(data).then(res => {
        if (res.code === 0) {
          this.$emits('tother', 'sign')
          this.loading = false
        } else {
          this.$message.error(res.msg)
          this.loading = false
        }
      }).catch(_ => {
        this.loading = false
      })
    },
    goHomeFun() {
      this.$router.replace({ path: '/' })
    }
  }
}

</script>
<style lang='less' scoped>
.login_resgiter {
  .login-form-title {
    & > img {
      width: 64px;
      height: 64px;
      margin-bottom: 40px;
      cursor: pointer;
    }
    & > h2 {
      color: @main-color;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 72px;
    }
    & > h2 > span {
      color: @color;
    }
  }

  .ainput {
    width: 480px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    outline: none !important;
    background: none !important;
    box-shadow: none;
    font-size: 18px;
    &:focus {
      border: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }
  }

  .abutton {
    width: 480px;
    height: 56px;
    background: @bg-color;
    border: 1px solid #e5e5e5;
    border-radius: 33px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .ainput_yzm {
    width: 310px;
    height: 40px;
  }
  .yzm {
    width: 160px;
    height: 40px;
    text-align: center;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
  }

  .radio_style {
    color: #999;
    margin-bottom: 64px;
  }

  :deep(.ant-radio-checked .ant-radio-inner) {
    border-color: #999 !important;

    &::after {
      background-color: @bg-color !important;
    }
  }
}
</style>
