<template>
  <div>
    <div class="login_resgiter">
      <div class="login-form-title">
        <img src="@/assets/images/icons/logo.png" alt="logo" @click="goHomeFun">
        <h2>欢迎打开<span>厨窗+</span></h2>
      </div>
      <a-form
        :model="formState"
        :rules="rules"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="phone">
          <a-input
            v-model:value="formState.phone"
            placeholder="手机号"
            class="ainput"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item name="yzm">
          <a-input
            v-model:value="formState.yzm"
            placeholder="验证码"
            class="ainput ainput_yzm db-mr10"
            autocomplete="off"
          />
          <a-button class="yzm" :disabled="isGetYzm" @click="getYZMFun">
            <a-statistic-countdown
              v-if="isGetYzm"
              :value="deadline"
              format="mm:ss"
              value-style="font-size: 18px;"
              style=""
              @finish="onFinish"
            />
            <span v-if="!isGetYzm">获取验证码</span>
          </a-button>
        </a-form-item>
        <a-radio
          v-model:checked="checked"
          class="radio_style"
        >我已经接受并同意厨窗+隐私条款</a-radio>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="formState.phone === '' || formState.yzm === ''"
            class="abutton"
            :loading="loading"
          >
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { sendcode, phoneLoginRegist } from '@/api/login'
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'
// 打包时需要把Cookie 注释
export default {
  name: 'Register',
  components: {},
  data() {
    return {
      deadline: '',
      reg: /^1\d{10}$/,
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        yzm: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      },
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      formState: {
        phone: '',
        yzm: ''
      },
      checked: false,
      isGetYzm: false,
      loading: false
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo'
    }),
    handleFinishFailed(errors) {
      console.log(errors)
    },
    handleFinish() { // 登录
      if (!this.checked) {
        this.$message.error('请阅读隐私条款并勾选')
        return
      }
      this.loading = true
      const data = {
        phone: this.formState.phone,
        code: this.formState.yzm
      }
      phoneLoginRegist(data).then((res) => {
        if (res.code === 0) {
          Cookies.set('MALL_TOKEN', res.token)
          this.getUserInfo()
          const MallToPath = sessionStorage.getItem('MallToPath')
          if (MallToPath) {
            if (sessionStorage.getItem('MallToPathQuery') !== '{}') {
              this.$router.replace({ path: MallToPath, query: JSON.parse(sessionStorage.getItem('MallToPathQuery')) })
              sessionStorage.removeItem('MallToPathQuery')
            } else {
              this.$router.replace({ path: MallToPath })
            }
            sessionStorage.removeItem('MallToPath')
          } else this.$router.replace({ path: '/' })
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(_ => {
        this.loading = false
      })
    },
    onFinish() {
      this.isGetYzm = false
    },
    getYZMFun() {
      if (!this.formState.phone) {
        this.$message.error('手机号不能为空')
        return
      }
      if (!this.reg.test(this.formState.phone)) {
        this.$message.error('手机号不正确')
        return
      }
      this.isGetYzm = true
      this.deadline = Date.now() + 1000 * 60 * 5
      const data = {
        phone: this.formState.phone
      }
      sendcode(data).then(res => {
        if (res.code === 0) {
          this.$message.success('获取验证码成功')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    goHomeFun() {
      this.$router.replace({ path: '/' })
    }
  }
}

</script>
<style lang='less' scoped>
.login_resgiter {
  .login-form-title {
    & > img {
      width: 64px;
      height: 64px;
      margin-bottom: 40px;
      cursor: pointer;
    }
    & > h2 {
      color: @main-color;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 72px;
    }
    & > h2 > span {
      color: @color;
    }
  }

  .ainput {
    width: 480px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    outline: none !important;
    background: none !important;
    box-shadow: none;
    font-size: 18px;
    &:focus {
      border: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }
  }

  .abutton {
    width: 480px;
    height: 56px;
    background: @bg-color;
    border: 1px solid #e5e5e5;
    border-radius: 33px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .ainput_yzm {
    width: 310px;
    height: 40px;
  }
  .yzm {
    width: 160px;
    height: 40px;
    text-align: center;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
  }

  .radio_style {
    color: #999;
    margin-bottom: 64px;
  }

  :deep(.ant-radio-checked .ant-radio-inner) {
    border-color: #999 !important;

    &::after {
      background-color: @bg-color !important;
    }
  }
}
</style>
