<template>
  <div class="login-container">
    <div class="login-form-box">
      <div class="login-form">
        <Sign v-if="LoginType === 'sign'" @tother="tother" />
        <Register v-if="LoginType === 'register'" @tother="tother" />
        <SignInByPhone v-if="LoginType === 'signPhone'" />
        <div class="other_login">
          <span>选择其他登录方式</span>
          <ul>
            <li class="other_login_item">
              <img src="@/assets/images/icons/icon-wechat.png" alt="微信登录">
              <span>微信登录</span>
            </li>
            <li v-show="LoginType === 'sign' || LoginType === 'register'" class="other_login_item" @click="LoginType = 'signPhone'">
              <img src="@/assets/images/icons/icon-phone.png" alt="短信登录">
              <span>短信登录</span>
            </li>
            <li v-show="LoginType === 'signPhone'" class="other_login_item" @click="LoginType = 'sign'">
              <img src="@/assets/images/icons/icon-user.png" alt="账号密码">
              <span>账号密码</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="login-left-box" />
  </div>
</template>

<script>
// import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import Sign from './signIn.vue'
import Register from './register.vue'
import SignInByPhone from './signInByPhone.vue'

export default {
  name: 'Login',
  components: {
    Sign,
    Register,
    SignInByPhone
  },
  data() {
    return {
      LoginType: 'sign'
    }
  },
  methods: {
    tother(val) {
      if (val === 'forget') this.$router.push({ path: '/login/forget' })
      else this.LoginType = val
    }
  }
}

</script>
<style lang='less' scoped>
.login-container {
  width: 100%;
  height: 1080px;
  display: flex;

  .login-form-box {
    width: 1120px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 95px;

    .login-form {
      width: 480px;
      height: 600px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .other_login {
        margin-top: 72px;

        & > span {
          color: #666666;
          font-weight: 400;
          font-size: 18px;
        }
        & > ul {
          display: flex;
          margin-top: 32px;
        }

        .other_login_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 32px;
          width: 72px;
          cursor: pointer;

          & > img {
            width: 42px;
            height: 42px;
            margin-bottom: 8px;
          }
          & > span {
            color: #666666;
            font-weight: 400;
            font-size: 18px;
          }
        }
      }
    }
  }

  .login-left-box {
    // width: 30%;
    height: 100%;
    color: @color;
    flex: 1;
    background: @bg-color;
  }
}
</style>
